import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import LottieAnimation from './LottieAnimation';
import heroImg from './assets/hero.json';
import contactImg from './assets/contact.json'
import { motion, } from "framer-motion";
import amreen from "./assets/amreen.jpeg";
import karthik from "./assets/karthik.jpeg";
import './App.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const App = () => {
  const myRef = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const [form, setForm] = useState({ name: "", mail: "", message: "", mobile: "" });
  const [errors, setErrors] = useState(false);
  const [buttonStatus, setButtonStatus] = useState('Send');
  let [scrollAmount, setScrollAmount] = useState(0)



  const executeScroll = () => myRef.current.scrollIntoView();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "HeedSites",
    "description": "Heedsites – Your Vision, Our Innovation. Professional web and mobile app development company specializing in custom solutions for businesses.",
    "image": "./assets/logo-dark.png",
    "url": "https://heedsites.com",
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "India"
    },
    "telephone": "8919907863",
    "email": "heedsites@gmail.com",
    "priceRange": "$$",
    "sameAs": [
      "https://www.linkedin.com/company/heedsites/"
    ],
    "serviceType": [
      "Web Development",
      "Mobile App Development",
    ]
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1// optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);


  const products = [
    { id: 3, name: "Online compiler", description: "Online compilers arikya.", image: require("./assets/onlinecompiler.png"), link: "https://arikya.in/python/online-compiler" },
    { id: 2, name: "Instant Backend", description: "Automate backend creation.", image: require("./assets/instantbackend.png"), link: "https://instantbackend.heedsites.com/" },
    { id: 1, name: "Arikya", description: "Training and placement", image: require("./assets/arikya.png"), link: "https://arikya.in" },
  ];
  const techStack = [
    { name: "React", logo: require("./assets/tech/reactjs.png") },
    { name: "Angular", logo: require("./assets/tech/angular.png") },
    { name: "Next.js", logo: require("./assets/tech/nextjs.png") },
    { name: "Vue", logo: require("./assets/tech/vuejs.png") },
    { name: "React Native", logo: require("./assets/tech/reactnative.png") },
    { name: "Figma", logo: require("./assets/tech/figma.png") },
    { name: "Python", logo: require("./assets/tech/python.png") },
    { name: "Node JS", logo: require("./assets/tech/nodejs.png") },
    { name: "Express JS", logo: require("./assets/tech/express.png") },
    { name: "MongoDB", logo: require("./assets/tech/mongodb.png") },
    { name: "Docker", logo: require("./assets/tech/docker.png") },
    { name: "AWS", logo: require("./assets/tech/aws.png") },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 90; // Adjust this value to scroll slightly above the section
      const y = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setMenuOpen(false)
    }
  };
  const handleScroll = () => {
    const sticky = window.scrollY > 0;
    setSticky(sticky);
  };

  useEffect(() => {
    setButtonStatus('Send');
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const submitForm = () => {
    setButtonStatus('Sending');
    if (form.message.length > 0) {
      axios.post('https://arikya.in/api/techloomMail', form)
        .then((response) => {
          console.log(response);
          setButtonStatus("Sent");
        })
        .catch((error) => console.log(error));
    } else {
      setErrors(true);
      setButtonStatus('Send');
    }
  };


  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 px-3 rounded-full shadow-lg z-10"
        onClick={() => onClick()}
      >
        <i className="bx bx-chevron-right text-2xl"></i>
      </button>
    );
  };


  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 px-3 rounded-full shadow-lg z-10 "
        onClick={() => onClick()}
      >
        <i className="bx bx-chevron-left text-2xl"></i>
      </button>
    );
  };




  const ProductCard = ({ product }) => {
    return (
      <a className="cursor-pointer" target='_blank' href={product.link}>
        <img src={product.image} draggable="false" alt={product.name} className="w-full h-full object-cover rounded-lg" />
        {/* <h3 className="text-lg font-bold mt-3">{product.name}</h3>
        <p className="text-gray-600 text-sm">{product.description}</p> */}
      </a>
    );
  };


  return (
    <div className="font-['Poppins']">
      <Helmet>
        <title>HeedSites | Your Vision, Our Innovation.</title>
        <meta name="description" content="Transform your business with HeedSites.Partner with HeedSites for innovative Web and Mobile applications, and technical resource solutions that drive business growth.Get custom-built, scalable applications." />
        <meta name="keywords" content="Heedsites, HeedSites, HEEDSITES, heedsites, web development, mobile app development, custom websites, technical resources, software development, React development, iOS apps, Android apps, web design, IT consulting" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="HeedSites | Your Vision, Our Innovation." />
        <meta property="og:description" content="Transform your business with HeedSites' Partner with HeedSites for innovative Web and Mobile applications, and technical resource solutions that drive business growth. Get custom-built, scalable applications." />
        <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta property="og:url" content="https://heedsites.com" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="HeedSites" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://heedsites.com" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {isSticky && (
        <div className="fixed top-0 w-full bg-white/90 shadow-md z-50 p-5 text-center">
          <img className="w-8 h-8 mx-auto" alt="Heedsites Logo" src={require('./assets/logo.png')} />
        </div>
      )}

      <div >
        {/* Header Section */}
        <header className="px-4  sm:px-8 lg:px-16 xl:px-32 2xl:px-48 pt-10  flex justify-between items-center">
          {/* Logo */}
          <div className="flex justify-center items-center text-xl xl:2x">
            <img className="w-6 h-6 sm:w-5 sm:h-5" src={require('./assets/logo.png')} alt="HeedSites logo" />
            <div className="font-semibold tracking-wide ml-1">EEDSITES</div>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6">
            {["home", "expertise", "products", 'about', "team", "contact"].map((id) => (
              <button
                key={id}
                onClick={() => scrollToSection(id)}
                className="text-black hover:text-black hover:text-[#729df2] transition duration-300"
              >
                {id.charAt(0).toUpperCase() + id.slice(1)}
              </button>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden relative">
            <button onClick={() => setMenuOpen(!menuOpen)} className="text-black">
              {menuOpen ? <i class='bx bx-x text-2xl' ></i> : <i class='bx bx-menu text-2xl' />}
            </button>

            {/* Animated Dropdown Menu */}
            {menuOpen && (
              <motion.div
                initial={{ opacity: 0, x: 30 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 30 }}
                className="absolute right-0 z-50 mt-2 w-40 bg-white shadow-md shadow-blue-200 rounded-lg overflow-hidden"
              >
                <div className="flex flex-col py-2">
                  {["home", "expertise", "products", 'about', "team", "contact"].map((id) => (
                    <button
                      key={id}
                      onClick={() => scrollToSection(id)}
                      className="px-4 py-2 text-black hover:bg-blue-100 transition duration-300"
                    >
                      {id.charAt(0).toUpperCase() + id.slice(1)}
                    </button>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        </header>



        {/* Hero Section */}
        <div id="home" class="relative  bg-gradient-to-t from-[#E7EEF8] to-[#FFFFFF] px-4 sm:px-8 lg:px-16 xl:px-32 2xl:px-48 py-10 md:py-16">
          {/* Services Section */}
            <div className="min-h-[70vh] flex items-center justify-between flex-col lg:flex-row relative z-10">
              <div className="lg:w-2/3">
                <h1 className="text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold">
                  Leading Innovation in Digital Solutions and{' '}
                  <span className="bg-gradient-to-r from-black to-[#ACC8FF] bg-clip-text text-transparent leading-normal">
                    Advanced
                  </span>{' '}
                  Product Development.
                </h1>
                <div className="text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl pr-0 lg:pr-20 mt-4">
                  We harness the power of advanced technology to solve complex business challenges. Our custom digital products are designed to drive growth, enhance efficiency, and equip businesses with the tools they need to succeed in an ever-evolving digital world.
                </div>
                <button
                  onClick={executeScroll}
                  className="mt-6 bg-[#ACC8FF] px-4 sm:px-6 py-2 sm:py-3 text-lg sm:text-xl rounded-md hover:bg-[#9BB8FF] relative z-20"
                >
                  Get Started
                </button>
              </div>
              <div className="lg:w-1/2">
                <LottieAnimation animationData={heroImg} />
              </div>
            </div>

            {/* Wave at the Bottom */}
            <div className="absolute bottom-0 left-0 w-full overflow-hidden z-0">
              <svg className="w-full" viewBox="0 0 1440 120" xmlns="http://www.w3.org/2000/svg">
                <path fill="#ffffff" d="M0,60 C200,120 400,0 600,60 C800,120 1000,0 1200,60 C1400,120 1600,0 1800,60 L1800,120 L0,120 Z"></path>
              </svg>
            </div>
          </div>

        </div>


        {/* Main Content */}
        <div>
          <div className="px-4 sm:px-8 lg:px-16 xl:px-32 2xl:px-48  py-7 mb-7 sm:text-base md:text-md lg:text-xl xl:text-xl 2xl:text-xl font-light leading-7 sm:leading-8 lg:leading-relaxed xl:leading-relaxed">
            <span>"</span>Are you looking for innovative digital solutions? Heedsites is a product-driven tech company focused on building cutting-edge software, automation tools, and scalable platforms for businesses worldwide. From SaaS products to Digital IT solutions, we specialize in crafting intelligent, high-performance applications that drive growth and efficiency. With a strong focus on innovation, automation, and user experience, <strong>Heedsites is redefining the future of digital transformation.</strong> 🚀
          </div>

          {/* core expertise */}
          <section id="expertise" class="bg-[#161c28] text-white px-6 sm:px-8 lg:px-16 xl:px-32 2xl:px-48 py-10">
            <div>
              <h1 className="text-2xl sm:text-2xl  sm:text-left lg:text-center md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-extrabold mb-8 sm:mb-8">Our Core Expertise</h1>
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">

                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-cloud text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">SaaS Development</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    We create cloud-based <strong>SaaS</strong> solutions that help businesses scale efficiently and securely with multi-tenancy and automation.
                  </p>
                </div>
                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-cart text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">E-Commerce Development</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    Creating scalable e-commerce platforms with secure payment gateways and customer engagement features.
                  </p>
                </div>
                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-globe text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">Progressive Web Applications (PWA)</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    We design fast, reliable, and installable PWAs, offering app-like experiences with offline capabilities.
                  </p>
                </div>
                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-mobile-alt text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">Cross-Platform Mobile App</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    Building high-performance mobile apps for seamless experiences on Android and iOS.
                  </p>
                </div>

                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-laptop text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">Web App Development</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    Specializing in high-performance web apps using trending frameworks.
                  </p>
                </div>
                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-paint text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">UI/UX Design</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    We design intuitive, aesthetically pleasing interfaces.
                  </p>
                </div>
                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-brain text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">Digital IT Solutions</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    Helping businesses adopt the latest tech for automation, optimization, and growth with expert IT consulting.
                  </p>
                </div>
                <div class="flex flex-col items-start text-left ">
                  <div class="bg-[#ACC8FF] p-3 text-black px-4 rounded-full">
                    <i class="bx bx-building text-xl"></i>
                  </div>
                  <h3 class="text-md font-semibold mt-4">Enterprise Solutions</h3>
                  <p class="text-blue-200 text-sm mt-2">
                    Offering custom enterprise solutions to optimize productivity with data-driven decision-making tools.
                  </p>
                </div>
              </div>
            </div>

          </section>

          {/* our products */}
          <div id="products" className=" py-28 px-6 relative">
            <h2 className="text-2xl sm:text-2xl  sm:text-left lg:text-center md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-extrabold">Our Products</h2>
            <p className="text-black sm:text-left lg:text-center mt-1 text-md">Explore our innovative solutions</p>


            <div className=" max-w-7xl mx-auto mt-8 overflow-hidden">

              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                dots={true}
                // autoPlaySpeed={1600}
                keyBoardControl={true}
                rewindWithAnimation={true}
                customTransition="transform 1500ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                shouldResetAutoplay={true}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
              >
                {products.map((product, index) => (
                  <div key={index} className=" w-full flex-shrink-0 ">
                    <ProductCard key={index} product={product} />
                  </div>
                ))}
              </Carousel>
            </div>



          </div>


          {/* meet */}
          <div id="team" className='flex justify-center my-10 mb-16'>
            <div className="relative flex-1 flex items-center justify-center bg-blue-200 shadow-2xl shadow-grey-100 rounded-2xl overflow-hidden py-16 max-w-5xl">
              {/* 3D Background - Abstract Waves */}
              <div className="absolute inset-0 ">
                <div className="absolute top-1/3 left-0 w-full h-1/2 bg-gradient-to-b from-white-800 via-white-900 to-black opacity-50 rounded-full blur-3xl"></div>
                <div className="absolute -top-40 left-1/2 w-96 h-96 bg-white rounded-full opacity-35 blur-2xl"></div>
                <div className="absolute -top-40 left-0 w-96 h-96 bg-blue-500 rounded-full opacity-30 blur-2xl"></div>
                <div className="absolute bottom-10 right-0 w-72 h-72 bg-[#dfbbd1] rounded-full opacity-30 blur-2xl"></div>
              </div>



              <div className="relative z-10 text-center">
                <h2 className="text-2xl sm:text-2xl  text-center md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-extrabold drop-shadow-md">Our Team</h2>
                <p className="text-gray-900 mt-2 text-md">A team of passionate individuals driving innovation and excellence.</p>





                {/* 3D Floating Cards */}
                {/* Team Member Cards */}
                <div className="flex w-full justify-center px-4 pb-10">
                  <div className="mt-12 flex flex-col sm:flex-row justify-center items-center gap-10 sm:gap-10 w-full max-w-4xl">
                    {/* Member 1 */}
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 200, damping: 10 }}
                      className="relative bg-white bg-opacity-10 backdrop-blur-lg h-56 w-56 rounded-3xl shadow-2xl flex flex-col items-center  border border-gray-300 p-1 mb-10"
                      style={{ boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)" }}
                    >
                      <img
                        className="w-full h-full rounded-2xl object-cover"
                        src={amreen}
                        alt="Shaik Amreen Kousar"
                      />
                      <h3 className="text-xl  mt-5 text-gray-900 text-center mb-2 whitespace-nowrap">
                        Amreen Kousar
                      </h3>
                    </motion.div>

                    {/* Member 2 */}
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 200, damping: 10 }}
                      className="relative bg-white bg-opacity-10 backdrop-blur-lg h-56 w-56 rounded-3xl shadow-2xl flex flex-col items-center  border border-gray-300 p-1 mb-10"
                      style={{ boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)" }}
                    >
                      <img
                        className="w-full h-full rounded-2xl object-cover"
                        src={karthik}
                        alt="Karthik Kovi"
                      />
                      <h3 className="text-xl  mt-5 text-gray-900 text-center">
                        Karthik Kovi
                      </h3>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* tech stack */}
          <section id="tech" className="bg-white text-[#161c28] py-12 px-6  mb-10">
            <div className="max-w-7xl mx-auto ">
              <h2 className="text-2xl sm:text-2xl sm:text-left lg:text-center md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-extrabold">Our Tech Stack</h2>
              <p className="text-md mt-1 sm:text-left lg:text-center">
                Advanced technologies powering innovative digital solutions.
              </p>
              <div className="rounded-2xl shadow-md shadow-blue-200 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6 mt-8">
                {techStack.map((tech, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center bg-white p-6 text-center"
                  >
                    <img
                      src={`${tech.logo}`}
                      alt={tech.name}
                      className="w-16 h-16 object-contain"
                    />
                    <p className="text-black font-semibold mt-2 text-center">{tech.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>


          {/* About Section */}
          <div id="about" className="text-black bg-slate-100 p-4 sm:p-8 lg:p-12 xl:p-16 2xl:p-20 px-4 sm:px-8 lg:px-16 xl:px-32 2xl:px-48 mb-10">
            <div className="flex flex-col lg:flex-row items-center gap-6 sm:gap-8 lg:gap-12">
              <div className="lg:w-1/2">
                <h1 className="text-2xl sm:text-2xl  md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-extrabold    mb-4 sm:mb-6 lg:mb-8">About us</h1>
                <span className="text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl">
                  <strong>Heedsites</strong> is a product-based startup committed to redefining how businesses and individuals interact with technology. Our mission is to develop innovative digital solutions that drive efficiency, foster growth,and empower businesses to thrive in the digital world.
                  <br /><br />
                  We specialize in crafting intelligent, scalable, and user-centric solutions that empower businesses to stay ahead in an ever-evolving digital landscape. Whether optimizing operations, enhancing productivity, or enabling seamless digital transformation, Heedsites is committed to delivering excellence through technology.
                </span>
              </div>
              <div className="lg:w-1/2">
                <img src={require('./assets/about.png')} className="w-full rounded-xl" alt="About" />
              </div>
            </div>
          </div>

          {/* Contact Section */}
          <div ref={myRef} id="contact" className="px-4 sm:px-8 lg:px-16 xl:px-32 2xl:px-48 py-12 md:py-16">
            <div className="flex flex-col lg:flex-row items-start gap-6 sm:gap-8 lg:gap-12">
              <div className="lg:w-1/2">

                <h1 className="flex text-2xl sm:text-2xl  md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-extrabold">Get In Touch ... <div className="w-32">
                  <div className='-mt-[3.6rem]'>
                    <LottieAnimation animationData={contactImg} />
                  </div>
                </div></h1>

                <span className="text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl">
                  Fill out the form below to get in touch with us. We are committed to understanding the specific challenges your business faces. Let’s discuss how our digital solutions can help solve them.
                </span>

              </div>
              <div className="lg:w-1/2 w-full space-y-4 sm:space-y-6">
                <div className="md:flex md:gap-2">
                  <div className="w-full mb-4 md:mb-0">
                    <label className="block mb-1 text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl">Name</label>
                    <input
                      onChange={(e) => setForm({ ...form, name: e.target.value })}
                      className="w-full p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block mb-1 text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl">Mobile Number</label>
                    <input
                      onChange={(e) => setForm({ ...form, mobile: e.target.value })}
                      className="w-full p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                </div>
                <div>
                  <label className="block mb-1 text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl">Email</label>
                  <input
                    onChange={(e) => setForm({ ...form, mail: e.target.value })}
                    className="w-full p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-sm sm:text-base md:text-md lg:text-md xl:text-md 2xl:text-xl">What can we help you with?</label>
                  <textarea
                    onChange={(e) => { setErrors(false); setForm({ ...form, message: e.target.value }) }}
                    className="w-full p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    rows="4"
                    placeholder='Drop a message regarding your requirement.'
                  />
                  {errors && <span className="text-red-600 text-sm">Please type the message</span>}
                </div>
                <button
                  onClick={submitForm}
                  className="float-right w-32 sm:w-40 bg-[#feecae] text-black py-2 px-4 rounded hover:shadow-2xl text-sm sm:text-base"
                >
                  {buttonStatus}
                </button>
              </div>
            </div>
          </div>

          <footer className="bg-[#161c28] text-white py-10 px-6 sm:px-8 lg:px-16 xl:px-32 2xl:px-48">
            <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">

              {/* Company Info */}
              <div>

                <div className="flex justify-start items-center mb-4 cursor-pointer" onClick={() => { scrollToSection("home") }}>
                  <img className="w-6 h-6 sm:w-5 sm:h-5" src={require('./assets/logo-dark.png')} alt="HeedSites logo" />
                  <div className="font-semibold tracking-wide ml-1">EEDSITES</div>
                </div>

                {/* <h3 className="text-xl font-semibold mb-3">Heedsites</h3> */}
                <p className="text-blue-200 text-sm">
                  Your Vision, Our Innovation.
                  {/* Innovating digital solutions for businesses worldwide. We build SaaS products, digital transformation tools, and scalable platforms to drive growth and efficiency. */}
                </p>
              </div>

              {/* Quick Links */}
              <div>
                <h3 className="text-lg font-semibold mb-3">Quick Links</h3>
                <div className="flex flex-col space-y-2">
                  <button onClick={() => { scrollToSection("about") }} className="text-blue-200 text-sm hover:text-white text-left">About Us</button>
                  <button onClick={() => { scrollToSection("team") }} className="text-blue-200 text-sm hover:text-white text-left">Team</button>
                  <button onClick={() => { scrollToSection("products") }} className="text-blue-200 text-sm hover:text-white text-left">Expertise</button>
                  <button onClick={() => { scrollToSection("contact") }} className="text-blue-200 text-sm hover:text-white text-left">Contact</button>
                </div>
              </div>

              {/* Services */}
              <div>
                <h3 className="text-lg font-semibold mb-3">Our Products</h3>
                <div className="flex flex-col space-y-2">
                  <button className="text-blue-200 text-sm text-left"><a href='https://arikya.in/python/online-compiler' target='_blank'>Online Compilers</a></button>
                  <button className="text-blue-200 text-sm text-left"><a href='https://instantbackend.heedsites.com/' target='_blank'>Instant Backend</a></button>
                  <button className="text-blue-200 text-sm text-left"><a href='https://arikya.in/' target='_blank'>Arikya</a></button>
                  {/* <button onClick={() => { scrollToSection("products") }} className="text-blue-200 text-sm text-left">Text To Speech</button>
                <button onClick={() => { scrollToSection("products") }} className="text-blue-200 text-sm text-left">Digital IT solutions</button> */}
                </div>
              </div>

              {/* Social Media & Contact */}
              <div>
                <h3 className="text-lg font-semibold mb-3">Connect With Us</h3>
                <div className="flex space-x-4 items-center">
                  <a href="https://www.linkedin.com/company/heedsites" target="_blank" rel="noopener noreferrer">
                    <button className="text-blue-200 hover:text-white text-2xl">
                      <i className="bx bxl-linkedin"></i>
                    </button>
                  </a>

                  <a href="https://www.instagram.com/heedsites/" target="_blank" rel="noopener noreferrer">
                    <button className="text-blue-200 hover:text-white text-2xl">
                      <i className="bx bxl-instagram"></i>
                    </button>
                  </a>

                  {/* <button className="text-blue-200 hover:text-white text-2xl">
                  <i className="bx bxl-facebook"></i>
                </button> */}
                </div>
                <p className="text-blue-200 text-sm mt-4">  📧 <a href="mailto:heedsites@gmail.com" className="hover:text-white">heedsites@gmail.com</a>
                </p>
              </div>
            </div>

            {/* Copyright */}
            <div className="mt-8 text-center text-gray-500 text-sm border-t border-gray-700 pt-5">
              © 2025 HeedSites. All rights reserved.
            </div>
          </footer>

        </div>
      </div >
      );
};

      export default App;