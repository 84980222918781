import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

function LottieAnimation({ animationData }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (animationData) {
      const animation = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });

      return () => {
        animation.destroy();
      };
    }
  }, [animationData]);

  return <div ref={containerRef} />;
}

export default LottieAnimation;